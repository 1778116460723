<template>
  <div>
    <b-button
      v-if="permissions.includes('novo_modelo')"
      @click="$router.push('/sistemicos/modelo/novo')"
      class="mb-2"
      variant="success"
      >Novo Modelo <fa-icon icon="plus"></fa-icon>
    </b-button>
    <Card class="mb-2">
      <template #card-body>
        <b-row class="pt-3">
          <b-col>
            <label>Canal</label>
            <b-form-select
              :options="selectOptionsChannels"
              v-model="selected.channel_id"
            ></b-form-select>
          </b-col>
          <b-col md="2">
            <FormSelect
              :title="'Aplicação:'"
              :options="applicationSelectOptions"
              v-model="selected.application_id"
            />
          </b-col>
          <b-col md="2">
            <FormSelect
              :title="'Ativo/Inativo'"
              :options="[
                { description: 'Todos', value: '' },
                { description: 'Somente Ativos', value: '1' },
                { description: 'Somente Inativos', value: '0' },
              ]"
              v-model="selected.active"
            />
          </b-col>
          <b-col md="2">
            <FormSelect
              :title="'Legados/Não Legados'"
              :options="[
                { description: 'Todos', value: '' },
                { description: 'Somente Legados', value: '1' },
                { description: 'Somente Não Legados', value: '0' },
              ]"
              v-model="selected.legacy"
            />
          </b-col>
          <b-col md="3">
            <FormSelect
              :title="'Tipo de Notificação'"
              :options="notificationSelectOptions"
              v-model="selected.notification_type_id"
            />
          </b-col>
        </b-row>
      </template>
      <hr />
      <div class="d-flex justify-content-end pb-3">
        <b-button @click="get_templates">Filtrar</b-button>
      </div>
    </Card>
    <Card>
      <template #card-body>
        <TableTemplatesList :templates="templates" />
      </template>
    </Card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: {
        channel_id: "",
        application_id: "",
        notification_type_id: "",        
        active: "",
        legacy: "",
      },     
    };
  },
  components: {
    TableTemplatesList: () => import("./components/TableTemplatesList.vue"),
    FormSelect: () => import("@/components/inputs/Select"),
  },
  computed: {
    permissions() {
      let permissions = [];
      try {
        const response =
          this.$store.state.auth.burgerPermissions.sistemicos.modelos.habilitar;
        if (response) {
          permissions = response;
        }
      } catch (e) {}

      return permissions;
    },
    applications() {
      return this.$store.state.templates.applications;
    },
    applicationsById() {
      return _.keyBy(this.applications, "id");
    },
    unityApplications() {
      return this.$store.state.templates.unityApplications;
    },
    applicationSelectOptions() {
      const unityApplications = _.map(this.unityApplications, (application) => {
        return {
          description: this.applicationsById[application.application_id].name,
          value: application.id,
        };
      });
      return [{ description: "Todas", value: "" }, ...unityApplications];
    },
    notificationSelectOptions() {
      const normalized = _.map(
        this.$store.state.templates.notificationTypes,
        (item) => {
          return { description: item.description, value: item.id };
        }
      );

      return [{ description: "Todos", value: "" }, ...normalized];
    },
    profileSelectOptions() {
      const normalized = _.map(this.$store.state.templates.profiles, (item) => {
        return { description: item.name, value: item.id };
      });

      return [{ description: "Todos", value: "" }, ...normalized];
    },
    selectOptionsChannels() {
      const firstOption = {
        text: "Selecione um canal",
        value: "",
        disabled: true,
      };
      const normalized = _.map(this.$store.state.templates.channels, (item) => {
        return {
          text: item.channel,
          value: item.id,
          disabled: item.active == "1" ? false : true,
        };
      });
      return [firstOption, ...normalized];
    },
    templates() {
      const sorted = _.sortBy(this.$store.state.templates.templatesList, [
        "application_name",
        "notification_type_id",
      ]);
      return sorted;
    },
  },
  methods: {
    get_templates() {
      this.$store.dispatch("templates/get_templates", this.selected);
    },
  },
  async mounted() {
    await this.$store.dispatch("templates/list_init");
  },
};
</script>

<style>
</style>